export default {
    key: 'ski',
    type: 'activity',
    steps: [
        {
            routeName: 'offerFlow-ski-steps-step-1-date',
            name: 'offerFlow.activities.ski.steps.step1',
            assistances: [],
        },
        {
            routeName: 'offerFlow-ski-steps-step-2-participants',
            name: 'offerFlow.activities.ski.steps.step2',
            assistances: [],
        },
    ],
} satisfies Flow;
