export default {
    key: 'swp',
    type: 'activity',
    steps: [
        {
            routeName: 'offerFlow-swp-steps-step-1-date',
            name: 'offerFlow.activities.swp.steps.step1',
            assistances: [],
        },
        {
            routeName: 'offerFlow-swp-steps-step-2-participants',
            name: 'offerFlow.activities.swp.steps.step2',
            assistances: [],
        },

    ],
} satisfies Flow;
