import * as Sentry from '@sentry/vue';
import { FetchError } from 'ofetch';

/**
 * Handles both expected and unexpected non-fatal errors.
 *
 * - Logs the error to the console.
 * - Shows a toast message if desired.
 * - Stops the loading spinner or anything blocking the UI.
 */
export function handleError(error: any, toastType: 'generic' | 'specific' | false = 'generic', context: object = {}) {
    let genericMessage = 'core.errorMessages.500';

    // Make sure API Errors are logged correctly, even when rethrown
    const rootError = error.cause as Error ?? error;

    // API Errors
    if (rootError instanceof FetchError) {
        genericMessage = 'core.errorMessages.apiGeneric';
        // Makes sure API Trace Id is logged
        const traceId = rootError?.response?.headers?.get('x-traceid');

        if (import.meta.dev) {
            console.error('API Error', rootError, rootError?.data?.detail);

            // eslint-disable-next-line no-console
            console.table([{
                message: rootError?.data?.detail,
                status: rootError.data?.status,
                traceId,
                ...context,
            }]);
        }

        try {
            Sentry.withScope((scope) => {
                scope.setTag('title', rootError?.data?.title);
                scope.setTag('message', rootError?.data?.detail); // is empty on prod
                scope.setTag('status', rootError.data?.status);
                scope.setTag('traceId', traceId);

                const msg = `API error: ${rootError?.data?.title}${rootError?.data?.detail ? ` - ${rootError?.data?.detail}` : ''}`;
                Sentry.captureException(new Error(msg));
            });
        }
        catch (error) {
            console.error('Error while logging to Sentry', error);
        }
    }

    // Generic Errors
    else {
        if (import.meta.dev) {
            console.error('Error', rootError);
            // eslint-disable-next-line no-console
            console.table([{
                ...context,
            }]);
        }

        try {
            Sentry.captureException(rootError);
        }
        catch (error) {
            console.error('Error while logging to Sentry', error);
        }
    }

    const { isLoading } = useLoading();
    isLoading.value = false;

    // Either no toast message is shown, a generic one or the actual technical Error message
    // Make sure to not expose technical details to the user, if not necessary
    if (toastType) {
        const { setErrorToast } = useToasts();
        const { t: $t, te: $te } = useNuxtApp().$i18n;
        if (toastType === 'generic') {
            setErrorToast($te(genericMessage) ? $t(genericMessage) : genericMessage);
        }
        else {
            setErrorToast(error.message);
        }
    }
}

/**
 * Handles Datatrans payment errors.
 * This includes regular failing payments and 3D secure errors.
 */
export function handleDatatransPaymentError(error: Error) {
    const { setErrorToast } = useToasts();
    const { t: $t } = useNuxtApp().$i18n;
    setErrorToast($t('core.errorMessages.paymentGeneric'));

    if (import.meta.dev) {
        console.error('Payment Datatrans Error', error);
    }
}
