/**
 * Reset flash toasts on every route change
 *
 * This means that toast messages that should only show once mer page (flash)
 * are removed when the user navigates to a new page.
 */
export default defineNuxtRouteMiddleware(() => {
    const { resetFlash } = useToasts();
    resetFlash();
});
