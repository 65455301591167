import type { RouteLocation } from 'vue-router';

/**
 * From given flow steps, return the current step based on the current route
 */
export function getCurrentStep(steps: Flow['steps'], currentRoute: RouteLocation) {
    const currentRouteName = getRouteName(currentRoute);
    return steps.find(step => step.routeName === currentRouteName);
}

/**
 * In a multi-step flow of routes (e.g. going through multiple steps in a booking process),
 * this function returns the next or previous route in the flow, based on the offset
 */
export function getAdjacentFlowStep(steps: Flow['steps'], currentRoute: RouteLocation, offset: number) {
    const current = getCurrentStep(steps, currentRoute);
    if (!current) {
        return undefined;
    }
    return steps[steps.indexOf(current) + offset];
}

/**
 * Returns the next step in the given flow
 */
export function getNextFlowStep(steps: Flow['steps'], currentRoute: RouteLocation) {
    return getAdjacentFlowStep(steps, currentRoute, 1);
}

/**
 * Returns the previous step in the given flow
 */
export function getPreviousFlowStep(steps: Flow['steps'], currentRoute: RouteLocation) {
    return getAdjacentFlowStep(steps, currentRoute, -1);
}

/**
 * Returns the localized Route for a given step
 */
export function getStepRoute(step?: FlowStep) {
    const localeRoute = useLocaleRoute();
    return step?.routeName ? localeRoute(step.routeName) : undefined;
}

/**
 * Returns the first localized route in the given flow
 */
export function getFirstFlowRoute(steps: Flow['steps'] = []) {
    return getStepRoute(steps[0]);
}

/**
 * Returns the last localized route in the given flow
 */
export function getLastFlowRoute(steps: Flow['steps'] = []) {
    return getStepRoute(steps[steps.length - 1]);
}
