/**
 * Global error handler that catches all unhandled errors.
 *
 * Please use the handleError() util for any actual handling, as its also used
 * for directly handling expected errors.
 *
 * You can create nuxt errors that are caught by this handler by throwing an object with the following structure:
 * In the data object you can specify a toastType to show a specific toast message.
 *
 * ```
 *  throw createError({
 *      message: 'Things went off the rails',
 *      data: {
 *          toastType: 'specific'
 *      }
 *  })
 * ```
 *
 * See: https://nuxt.com/docs/getting-started/error-handling
 */

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('vue:error', (error: any, instance, info) => {
        handleError(error, error.data?.toastType ?? false, { instance, info });
    });
});
