<script setup lang="ts">
import type { NuxtError } from '#app';

/**
 * Global Fullscreen Error Page
 */

const { error } = defineProps<{
    error: NuxtError;
}>();

function handleError() {
    clearError({ redirect: '/' });
}
</script>

<template>
    <div class="flex min-h-dvh items-center justify-center bg-brand-b px-container-gap text-white">
        <Logo class="absolute left-container-gap top-5 md:top-7" />
        <PaneNarrow class="text-center">
            <template v-if="error?.statusCode === 404">
                <h1 class="mb-2">
                    {{ $t('core.errorMessages.404') }}
                </h1>
            </template>

            <template v-else>
                <h1 class="mb-2">
                    {{ $t('core.errorMessages.500') }}
                </h1>
                <p class="">
                    {{ error?.message }}
                </p>
            </template>
            <BaseButton class="mx-auto mt-8 block" @click="handleError">
                {{ $t('core.buttons.toStart') }}
            </BaseButton>
        </PaneNarrow>
    </div>
</template>
