import { parseJSON } from 'date-fns';
import type { StateTree } from 'pinia';

/**
 * JSON.parse() does not properly recreate Date objects by default.
 * This function is used as a reviver function to recreate Date objects from ISO strings.
 */
function revivePersistedDate(key: string, value: any) {
    if (typeof value === 'string' && /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ$/.test(value)) {
        try {
            const date = parseJSON(value);
            return !Number.isNaN(date.getTime()) ? date : value;
        }
        catch (err) {}
    }
    return value;
}

const persistSerializer = {
    deserialize: (v: string) => v ? JSON.parse(v, revivePersistedDate) : null,
    serialize: (v: StateTree) => JSON.stringify(v),
};

/**
 * Default Store Persist Options for "Pinia Persisted State Plugin" that uses localStorage storage
 * and a custom serializer to handle Date objects.
 */
const storePersistOptions = {
    storage: persistedState.localStorage,
    serializer: persistSerializer,
};

export default {
    storePersistOptions,
};
