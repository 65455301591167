export interface Toast {
    key: string;
    type: string;
    message: string;
    duration: number;
    data?: any;
    flash?: boolean;
}

export default function useToasts() {
    const toasts = useState<Record<string, Toast>>('toasts', () => ({}));

    function addToast(key: string = 'info', type: string = 'info', message: string = '', duration: number = 0, flash = true, data?: any) {
        toasts.value[key] = { key, type, message, duration, flash, data };
    }

    function setErrorToast(message: string = '', duration: number = 0, flash = true) {
        addToast('error', 'error', message, duration, flash);
    }

    function setInfoToast(message: string = '', duration: number = 0, flash = true) {
        addToast('info', 'info', message, duration, flash);
    }

    function reset() {
        toasts.value = {};
    }

    function resetFlash() {
        toasts.value = Object.fromEntries(
            Object.entries(toasts.value).filter(([, toast]) => !toast.flash),
        );
    }

    function resetInfoToast() {
        toasts.value = Object.fromEntries(
            Object.entries(toasts.value).filter(([, toast]) => toast.key !== 'info'),
        );
    }

    return {
        toasts,
        addToast,
        setErrorToast,
        setInfoToast,
        reset,
        resetFlash,
        resetInfoToast,
    };
}
