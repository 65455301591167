interface OnetrustScript {
    OptanonWrapper: () => void;
    OnetrustActiveGroups: string;
}

declare global {
    interface Window extends OnetrustScript {}
}

export default defineNuxtPlugin(() => {
    const runtimeConfig = useRuntimeConfig();

    if (!runtimeConfig.public.onetrust.enabled || !runtimeConfig.public.onetrust.key || !runtimeConfig.public.gtm.id) {
        return;
    }

    // GTM Script
    // only loaded after Cookie Consent
    const { proxy: proxyGTM, load: loadGTM, onLoaded: onLoadedGTM } = useScriptGoogleTagManager({
        id: runtimeConfig.public.gtm.id,
        scriptOptions: {
            trigger: 'manual',
        },

    });

    // Track Page View
    function trackPageView(title: string | undefined, path: string) {
        proxyGTM.dataLayer.push({
            event: 'page_view',
            title,
            path,
        });
    }

    onLoadedGTM(() => {
        proxyGTM.dataLayer.push({
            platform_info_environment: runtimeConfig.public.gtm.env,
        });

        proxyGTM.dataLayer.push({
            platform_info_platformName: 'topoftravel',
        });

        // Page View
        useScriptEventPage(({ title, path }) => {
            trackPageView(title, path);
        });

        const route = useRoute();
        trackPageView(document.title, route.fullPath);
    });

    // OneTrust Script, used to load GTM after Cookie Consent and show Cookie Banner
    useScript({
        'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-domain-script': runtimeConfig.public.onetrust.key,
    });

    // Load GTM after Cookie Consent
    window.OptanonWrapper = function () {
        if (window.OnetrustActiveGroups?.includes('C0004')) {
            loadGTM();
        }
    };
});
